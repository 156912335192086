import React from "react";
import CryptoTransfer from "./CryptoTransfer";
import { Toaster } from "sonner";

function App() {
  return (
    <>
      <Toaster position="top-center" />
      <div className="App">
        <CryptoTransfer />
      </div>
    </>
  );
}

export default App;
